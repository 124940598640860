<template>
  <div class="content">
    <!-- dialogFormulario NUEVA COMISION -->
    <v-row justify="center">
      <div class="col-md-2" align="left">
        <v-btn fab dark x-small color="primary">
          <a @click="$router.go(-1)">
            <v-icon dark> arrow_back</v-icon>
          </a>
        </v-btn>
      </div>

      <v-dialog v-model="dialogFormulario" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div class="col-md-10" align="right">
            <v-btn color="primary" dark v-bind="attrs" v-on="on">
              Nueva comisión
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title
            style="font-family: 'Montserrat';"
            class="font-weight-bold"
          >
            {{ editar ? "Modificar" : "Nueva" }} comisión

            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="limpiar_campos" icon v-bind="attrs" v-on="on">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>
          <!-- LOADING -->
          <div v-if="procensando_datos">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            style="padding: 5px 30px;"
          >
            <!-- SELECT CHANNELS -->
            <v-select
              return-object
              v-model="channels"
              :items="canalespago.filter((canal) => canal.Id != 0)"
              item-text="Nombre"
              item-value="Id"
              :rules="[(v) => !!v || 'El canal de pago es requerido']"
              label="Canal de pago"
              required
              @change="changeChannel"
              @focus="mediopago_id = 0"
            ></v-select>
            <v-col
              class="d-flex flex-column px-0"
              v-if="channels && channels.Id === 1"
            >
              <span class="grey--text text--darken-1 mb-2 body-2">
                Seleccione si la comisión del canal de pago
                <span class="font-weight-bold">crédito</span> es para una o
                varias cuotas.
              </span>
              <v-btn-toggle
                v-model="cantidad_cuotas"
                mandatory
                dense
                color="green"
              >
                <v-btn value="una" text class="px-4">
                  <span class="font-weight-bold">Una </span>
                  cuota
                </v-btn>

                <v-btn value="varias" text class="px-4">
                  <span class="font-weight-bold">Varias</span>
                  cuotas
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <!-- SELECT MEDIOS PAGO -->
            <v-autocomplete
              v-model="mediopago_id"
              :items="medios"
              item-text="mediopago"
              item-value="ID"
              dense
              filled
              v-if="channels && channels.Id === 1"
              label="Medio de pago"
              @change="changeArancel()"
            >
            </v-autocomplete>

            <!-- NOMBRE -->
            <v-text-field
              v-model="CuentaComision"
              :counter="30"
              :rules="nameCuentaComision"
              label="Nombre o descripción"
              required
            ></v-text-field>

            <!-- valor y total comision -->
            <v-row no-gutters>
              <v-col class="mr-2">
                <!-- VALOR -->
                <v-text-field
                  v-model="Comision"
                  :counter="30"
                  :rules="valorComision"
                  label="Valor de Comisión"
                  hint="Ejemplo: 0.1234"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <!-- TOTAL COMISION (suma de Comision y Arancel) -->
                <v-text-field
                  :value="TotalComision"
                  label="Total Comisión"
                  readonly
                  disabled
                  class="readonly-textfield"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col class="mr-2">
                <!-- MINIMO -->
                <v-text-field
                  v-model="minimo"
                  label="Valor mínimo"
                  prefix="$"
                  type="number"
                  min="0"
                ></v-text-field
              ></v-col>
              <!-- total minimo -->
              <v-col class="mr-2">
                <v-text-field
                  :value="TotalMinimo"
                  label="Total mínimo"
                  prefix="$"
                  type="number"
                  min="0"
                  readonly
                  disabled
                  class="readonly-textfield"
                ></v-text-field>
              </v-col>
              <v-col class="mr-2"
                ><!-- MAXIMO -->
                <v-text-field
                  v-model="maximo"
                  label="Valor máximo"
                  prefix="$"
                  type="number"
                  min="0"
                ></v-text-field
              ></v-col>
              <!-- total maximo -->
              <v-col>
                <v-text-field
                  :value="TotalMaximo"
                  label="Total máximo"
                  prefix="$"
                  type="number"
                  min="0"
                  readonly
                  disabled
                  class="readonly-textfield"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- RADIO SELECT UNA O VARIAS CUOTAS -->
            <!--  <v-radio-group
              v-model="cantidad_cuotas"
              row
              @change="changeArancel"
            >
              <template v-slot:label>
                <div>
                  Seleccione si la comisión del canal de pago crédito es para
                  una o varias cuotas.
                </div>
              </template>
              <v-radio value="una">
                <template v-slot:label>
                  <div><strong class="primary--text">Una</strong> cuota</div>
                </template>
              </v-radio>
              <v-radio value="varias">
                <template v-slot:label>
                  <div>
                    <strong class="primary--text">Varias</strong> cuotas
                  </div>
                </template>
              </v-radio>
            </v-radio-group> -->

            <!-- channels arancel -->
            <v-select
              v-model="selectArancel"
              return-object
              :items="channelsArancel"
              :item-text="nombreArancel"
              item-value="Id"
              :rules="[(v) => !!v || 'El arancel es requerido']"
              label="Arancel"
              no-data-text="Sin datos disponibles"
              required
            ></v-select>

            <!-- vigencia desde -->
            <v-row>
              <v-col>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  min-width="auto"
                  class="mt-2"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <!-- valor del text -->
                    <v-text-field
                      prepend-inner-icon="mdi-calendar"
                      label="Vigencia desde"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      persistent-hint
                      v-model="VigenciaDesde"
                      :rules="[
                        (v) => !!v || 'La fecha de vigencia es requerido',
                      ]"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="VigenciaDesde"
                    :rules="[(v) => !!v || 'La fecha de vigencia es requerido']"
                    locale="es-ar"
                    no-title
                    scrollable
                    dark
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <!-- checkbox vigencia -->
              <v-col>
                <v-checkbox
                  v-model="vigente"
                  label="Vigente"
                  hide-details
                  class="mt-4"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="limpiar_campos">
              Cerrar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="cargando"
              color="green"
              class="font-weight-bold"
              style="font-family: 'Montserrat';"
              text
              @click="guardar_cuentacomision()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- END dialogFormulario NUEVA COMISION -->
    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Comisiones</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <!-- checkbox filtro vigentes -->
                <v-checkbox
                  v-model="mostrarTodos"
                  label="Mostrar todos"
                  hide-details
                  class="mr-2"
                  @change="getCuentasComision"
                ></v-checkbox>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="comisiones"
                :loading="cargando"
                :items-per-page="pagination.visible"
                hide-default-footer
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-0 trow"
                disable-sort
              >
                <!-- Headers -->
                <!-- Coeficiente-TelCo -->
                <template v-slot:[`header.Comision`]="{ header }">
                  <span class="text-green-500">{{ header.text }}</span>
                </template>

                <!-- Min-TelCo -->
                <template v-slot:[`header.ImporteMinimo`]="{ header }">
                  <span class="text-green-500">{{ header.text }}</span>
                </template>

                <!-- Max-TelCo -->
                <template v-slot:[`header.ImporteMaximo`]="{ header }">
                  <span class="text-green-500">{{ header.text }}</span>
                </template>

                <!-- Min Proveedor -->
                <template
                  v-slot:[`header.Channelarancel.Importeminimo`]="{ header }"
                >
                  <span class="text-blue-500">{{ header.text }}</span>
                </template>

                <!-- Max Proveedor -->
                <template
                  v-slot:[`header.Channelarancel.Importemaximo`]="{ header }"
                >
                  <span class="text-blue-500">{{ header.text }}</span>
                </template>

                <!-- Coeficiente Proveedor -->
                <template v-slot:[`header.Channelarancel.Importe`]="{ header }">
                  <span class="text-blue-500">{{ header.text }}</span>
                </template>

                <!-- Items -->

                <template v-slot:[`item.VigenciaDesde`]="{ item }">
                  {{ moment.utc(item.VigenciaDesde).format("DD/MM/YYYY") }}
                </template>

                <template v-slot:[`item.Mediopagoid`]="{ item }">
                  {{ obtenerMedioPago(item.Mediopagoid) }}
                </template>
                <!-- Tipo calculo -->
                <template v-slot:[`item.Channelarancel.Tipocalculo`]="{ item }">
                  {{
                    item.Channelarancel.Tipocalculo == ""
                      ? "-"
                      : item.Channelarancel.Tipocalculo
                  }}
                </template>

                <template v-slot:[`item.CoeficienteTotal`]="{ item }">
                  {{ item.CoeficienteTotal.toFixed(4) }}
                </template>

                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn icon class="mr-2" @click="showDetalle(item)">
                    <v-icon color="grey darken-1">
                      mdi-eye
                    </v-icon>
                  </v-btn>
                  <v-icon class="mr-2" color="orange" @click="editItem(item)">
                    create
                  </v-icon>
                </template>
                <!--END  BOTONES DE LA TABLA -->
              </v-data-table>

              <!--PAGINACIÓN-->
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>

              <DetalleCuentaComision ref="dialogDetalle" />
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { CuentaComision } from "../../services/cuentacomision.js";
import { Cuentas } from "../../services/cuentas.js";
import DetalleCuentaComision from "./Clientes/DetalleCuentaComision.vue";

export default {
  name: "CuentaComision",
  data() {
    return {
      vigente: false,
      cantidad_cuotas: "una",
      showRadios: false,
      minimo: 0,
      maximo: 0,
      mediopago_id: 0,
      medios: [
        { ID: 0, mediopago: "TODOS" },
        { ID: 30, mediopago: "AMERICAN EXPRESS" },
      ],
      cliente_id: 0,
      cuenta_id: 0,
      comisiones: [],
      idCuentaComision: null,
      mostrarTodos: false,
      cargando: false,
      dialogFormulario: false,
      // mostrar error de api
      errored: false,
      // res indica el resultado de las validaciones
      res: false,
      //loaders para procesar datos dee envio
      procensando_datos: false,
      // lista de pagos tipos.
      search: "",
      channelsArancel: [],
      selectArancel: null,
      cargarChannel: 1,
      cargarRubro: 1,
      rubroId: 1,
      headers: [
        { text: "Descripción", value: "CuentaComision" },
        { text: "Medio de Pago", value: "Channel" },
        {
          text: "Tipo Coeficiente",
          value: "Channelarancel.Tipocalculo",
          align: "center",
        },
        // { text: "Medio", value: "Mediopagoid", sortable: false },
        { text: "Coeficiente TelCo", value: "Comision", align: "center" },
        {
          text: "Min TelCo",
          value: "ImporteMinimo",
          sortable: false,
          align: "center",
        },
        {
          text: "Max TelCo",
          value: "ImporteMaximo",
          sortable: false,
          align: "center",
        },

        {
          text: "Coeficiente Proveedor",
          value: "Channelarancel.Importe",
          align: "center",
        },
        {
          text: "Min Proveedor",
          value: "Channelarancel.Importeminimo",
          align: "center",
        },
        {
          text: "Max Proveedor",
          value: "Channelarancel.Importemaximo",
          align: "center",
        },
        {
          text: "Coeficiente Total",
          value: "CoeficienteTotal",
          align: "center",
        },
        { text: "Vigente", value: "VigenciaDesde", align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      menu2: false,
      // paginacion api
      pagination: {
        page: 1,
        total: 0,
        visible: 50,
      },
      // campos para nueva cuenta comisión POST
      CuentasId: "",
      CuentaComision: "",
      nameCuentaComision: [
        (v) => !!v || " Cuenta comisión es requerido",
        (v) =>
          (v && v.length <= 30) ||
          "Cuenta comisión no debe superar los 30 caracteres",
      ],
      Comision: "",
      valorComision: [
        (v) => !!v || "El valor de la Comisión es requerido",
        (v) =>
          /^\d*(\.\d{1,4})?$/.test(v) ||
          "El campo valor es númerico, de hasta cuatro dígitos decimales.",
      ],
      VigenciaDesde: "",
      nameVigenciaHasta: [(v) => !!v || "Vigencia Hasta es requerido"],
      channels: null,
      // validaciones
      valid: false,
      editar: false,
    };
  },
  created() {
    //this.cuenta_id = ;
    //this.cliente_id = this.$route.params.cliente_id;
    this.getCuenta(this.$route.params.cuenta_id);
  },
  computed: {
    ...mapGetters("movimientos", ["canalespago"]),
    FormatDateHasta() {
      return this.VigenciaDesde
        ? moment(this.VigenciaDesde).format("DD/MM/YYYY")
        : "";
    },
    TotalComision() {
      return this.channels && this.selectArancel && this.Comision
        ? this.channels.Channel !== "DEBIN"
          ? (
              (parseFloat(this.Comision) +
                parseFloat(this.selectArancel.Importe)) *
              100
            ).toFixed(2)
          : (parseFloat(this.Comision) * 100).toFixed(2)
        : 0;
    },
    TotalMinimo() {
      return this.channels && this.selectArancel
        ? this.channels.Channel !== "DEBIN"
          ? parseInt(this.minimo) + parseInt(this.selectArancel.Importeminimo)
          : parseInt(this.minimo)
        : 0;
    },
    TotalMaximo() {
      return this.channels && this.selectArancel
        ? this.channels.Channel !== "DEBIN"
          ? parseInt(this.maximo) + parseInt(this.selectArancel.Importemaximo)
          : parseInt(this.maximo)
        : 0;
    },
  },
  methods: {
    ...mapActions("movimientos", ["getChannels"]),
    async getCuenta(id) {
      const { data } = await Cuentas.getCuentaID({ id });
      this.cliente_id = data.clientes_id;
      this.cuenta_id = data.id;
      this.rubroId = data.rubros_id;
      this.getCuentasComision();
      this.getChannels();
    },
    changeChannel(canal) {
      if (canal.Nombre == "CREDITO") {
        this.showRadios = true;
      } else {
        this.showRadios = false;
        this.cantidad_cuotas = "una";
      }
      this.channels = canal;
      this.changeArancel();
    },
    nombreArancel(arancel) {
      return (
        arancel.Rubro +
        " - " +
        arancel.Importe.toFixed(4) +
        " - VIGENCIA: " +
        moment(arancel.Fechadesde)
          .add(1, "days")
          .format("DD/MM/YYYY")
      );
    },
    changeArancel() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        CargarChannel: this.cargarChannel,
        PagoCuota: this.cantidad_cuotas == "varias" ? true : false,
        MedioPagoId: this.mediopago_id,
        CargarRubro: this.cargarRubro,
        ChannelId: this.channels.Id,
        RubrosId: this.rubroId,
      };
      this.cargando = true;
      CuentaComision.getChannelsArancel(params)
        .then((response) => {
          if (response.data.data == null) {
            this.channelsArancel = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.channelsArancel = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    //listar las cuentas comision
    getCuentasComision() {
      const params = {
        Number: 1,
        Size: 20,
        CuentaId: parseInt(this.cuenta_id),
        CargarCuenta: true,
        SoloVigentes: this.mostrarTodos ? false : true,
      };
      this.cargando = true;
      CuentaComision.getCuentaComision(params)
        .then((response) => {
          if (response.data.data == null) {
            this.comisiones = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.comisiones = response.data.data || [];
            // ordenar por fecha de vigencia desde descendente
            this.comisiones = this.sortByVigenciaDesdeDesc(this.comisiones);
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    sortByVigenciaDesdeDesc(arreglo) {
      return arreglo
        .sort(function(a, b) {
          if (a.VigenciaDesde > b.VigenciaDesde) {
            return 1;
          }
          if (a.VigenciaDesde < b.VigenciaDesde) {
            return -1;
          }
          // a must be equal to b
          return 0;
        })
        .reverse();
    },
    //retorna el nombre de medio de pago para mostrarlo al listado en la tabla
    obtenerMedioPago(idMedioPago) {
      const medio_pago = this.medios.find(({ ID }) => ID == idMedioPago);
      return medio_pago.mediopago;
    },
    // paginar
    onPageChange() {
      this.getCuentasComision();
    },
    getChannelsArancel() {
      const params = {
        Number: 1,
        Size: this.rowsPerPage,
        CargarChannel: this.cargarChannel,
        CargarRubro: this.cargarRubro,
      };
      this.cargando = true;
      CuentaComision.getChannelsArancel(params)
        .then((response) => {
          if (response.data.data == null) {
            this.channelsArancel = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
          } else {
            this.channelsArancel = response.data.data;
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    //comfirmar nueva cuenta comision
    async guardar_cuentacomision() {
      if (this.$refs.form.validate()) {
        this.error = false;
        this.procensando_datos = true;
        const payload = {
          CuentasId: parseInt(this.cuenta_id),
          CuentaComision: this.CuentaComision,
          Comision: parseFloat(this.Comision),
          VigenciaDesde: moment(this.VigenciaDesde).format(),
          Vigente: this.vigente,

          ChannelsId: this.channels.Id,
          Mediopagoid: this.mediopago_id,
          ImporteMinimo: parseFloat(this.minimo),
          ImporteMaximo: parseFloat(this.maximo),
          ChannelarancelesId: parseInt(this.selectArancel.Id),
        };

        // ver comision para credito en una o varias cuotas
        this.cantidad_cuotas === "una"
          ? (payload.pagocuota = false)
          : (payload.pagocuota = true);
        if (!this.editar) {
          await CuentaComision.nuevo_cuentacomision(payload)
            .then(() => {
              this.$toastr.s("Cuenta comisión se creo correctamente");
              // actualizar lista de pagos tipos
              this.getCuentasComision();
              // cerrar dialogFormulario create channel
              this.limpiar_campos();
            })
            .catch((error) => {
              // this.errored = true;
              const data = error.response.data;
              this.error = true;
              this.$toastr.e(data.message);
            })
            .finally(() => (this.procensando_datos = false));
        } else {
          payload.Id = this.idCuentaComision;
          await CuentaComision.actualizar_cuentacomision(payload)
            .then(() => {
              this.$toastr.s("Cuenta comisión actualizado correctamente");
              // actualizar lista de pagos tipos
              this.getCuentasComision();
              // cerrar dialogFormulario create channel
              this.limpiar_campos();
            })
            .catch((error) => {
              // this.errored = true;
              const data = error.response.data;
              this.error = true;
              this.$toastr.e(data.message);
            })
            .finally(() => (this.procensando_datos = false));
        }
      }
    },
    //consultar los datos de la comision a modificar. Boton editar comision
    editItem(item) {
      const channel = this.canalespago.find(({ Id }) => Id == item.ChannelsId);
      this.idCuentaComision = item.Id;
      this.CuentaComision = item.CuentaComision;
      this.Comision = item.Comision;
      this.minimo = item.ImporteMinimo;
      this.maximo = item.ImporteMaximo;
      this.VigenciaDesde = moment(item.VigenciaDesde).format("YYYY-MM-DD");
      //this.channels = channel;
      this.cantidad_cuotas = item.Channelarancel.Pagocuota ? "varias" : "una";
      this.mediopago_id = item.Mediopagoid;
      this.editar = true;
      this.dialogFormulario = true;
      this.changeChannel(channel);
      this.selectArancel = item.Channelarancel;
      this.vigente = item.Vigente;
    },
    // limpiar_campos
    limpiar_campos() {
      this.$refs.form.reset();
      this.dialogFormulario = false;
      this.editar = false;
    },
    // error de api , esta funcion vuelve a recargar la pagina
    intento() {
      this.$router.go();
    },

    showDetalle(item) {
      const data = {
        nombre: item.CuentaComision,
        channel: item.Channel,
        comision: item.Comision,
        channelImporte: item.Channelarancel.Importe,
        channelMinimo: item.Channelarancel.Importeminimo,
        channelMaximo: item.Channelarancel.Importemaximo,
        minimo: item.ImporteMinimo,
        maximo: item.ImporteMaximo,
        vigenciaDesde: item.VigenciaDesde,
      };

      this.$refs.dialogDetalle.$emit("showDetalle", data);
    },
  },
  components: { DetalleCuentaComision },
};
</script>
<style scoped>
a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}

.readonly-textfield::v-deep input[disabled] {
  color: #000000;
}
.readonly-textfield::v-deep label[for] {
  color: rgba(0, 0, 0, 0.6);
}

.readonly-textfield::v-deep .v-text-field__prefix {
  color: #000000;
}
</style>
