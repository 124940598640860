<template>
  <div>
    <div class="row">
      <div class="col" align="right">
        <v-row justify="center">
          <!------ NUEVO USUARIO -------->
          <v-dialog v-model="createUsers" persistent max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <div class="col-md-12" align="right">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="abrir_create_users()"
                >
                  Nuevo usuario
                </v-btn>
              </div>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Nuevo usuario</span>
                <v-spacer></v-spacer>
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="cerrar_create()"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>close</v-icon>
                    </v-btn>
                  </template>
                </v-menu>
              </v-card-title>
              <div v-if="procensando_datos">
                <loader
                  object="#4caf50"
                  color1="#ffffff"
                  color2="#17fd3d"
                  size="5"
                  speed="2"
                  bg="#343a40"
                  objectbg="#999793"
                  opacity="40"
                  disableScrolling="false"
                  name="box"
                ></loader>
              </div>
              <v-card-text>
                <v-container>
                  <v-form
                    ref="form_create_user"
                    v-model="valid"
                    lazy-validation
                  >
                    <!-- NOMBRE -->
                    <v-text-field
                      v-model="nombre"
                      :counter="22"
                      :rules="namenombre"
                      type="text"
                      label="Nombre"
                      required
                    ></v-text-field>
                    <!-- EMAIL -->
                    <v-text-field
                      v-model="email"
                      :counter="50"
                      :rules="nameuser"
                      type="email"
                      label="Correo"
                      required
                    ></v-text-field>

                    <v-row
                      justify="center"
                      style="gap: 20px; margin-inline: auto"
                    >
                      <!-- PASSWORD -->
                      <v-text-field
                        v-model="passwords"
                        :type="show ? 'text' : 'password'"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :counter="15"
                        :rules="namepassword"
                        label="Contraseña"
                        @click:append="show = !show"
                        required
                      ></v-text-field>

                      <!-- CONFIRMAR PASSWORD -->
                      <v-text-field
                        v-model="confirmar_password"
                        :type="show1 ? 'text' : 'password'"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :counter="15"
                        :rules="namepassword"
                        label="Confirmar Contraseña"
                        @click:append="show1 = !show1"
                        required
                      ></v-text-field>
                    </v-row>
                    <!-- CLIENTE -->
                    <v-select
                      v-model="clienteID"
                      :items="clientesID"
                      item-text="cliente"
                      :item-value="'id'"
                      :rules="[(v) => !!v || 'Cliente es requerido']"
                      label="Cliente"
                      required
                      @change="cliente_cuenta(clienteID)"
                    ></v-select>

                    <!-- CUENTA -->
                    <v-select
                      v-model="cuenta"
                      :items="cuentas"
                      item-text="cuenta"
                      item-value="id"
                      label="Cuentas"
                      multiple
                      no-data-text="No hay datos disponibles"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggle">
                          <v-list-item-action>
                            <v-icon
                              :color="likesAllCuentas ? 'indigo darken-4' : ''"
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              TODAS LAS CUENTAS
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                    <!-- FIN SELECT CUENTA -->

                    <!-- SELECT POS -->
                    <v-select
                      v-show="cajas.length > 0"
                      v-model="cajaSelected"
                      :items="cajas"
                      item-text="descripcion"
                      item-value="Id"
                      label="Caja"
                      :disable="cuenta.length == 0"
                      no-data-text="No hay datos disponibles"
                    ></v-select>

                    <!-- FIN SELECT POS -->
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="cerrar_create()">
                  Cerrar
                </v-btn>
                <v-btn
                  :disabled="!valid"
                  :loading="dialog"
                  color="blue darken-1"
                  text
                  @click="guardar_nuevo_user()"
                >
                  Guardar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!------ END NUEVO USUARIO -------->
        </v-row>
      </div>
    </div>
    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Usuarios</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-4 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchUser"
                  append-icon="mdi-magnify"
                  label="Buscar Usuario"
                  single-line
                  hide-details
                  clearable
                >
                </v-text-field>
              </v-card-title>

              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :headers="headers"
                :items="desserts"
                :loading="cargando"
                :items-per-page="rowsPerPage"
                hide-default-footer
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1 trow"
              >
                <template v-slot:no-results>
                  <v-alert color="warning" icon="warning">
                    No se encontraron registros
                  </v-alert>
                </template>
                <template v-slot:no-data>
                  <v-alert color="purple lighten-3" icon="warning">
                    Obteniendo registros... aguarde por favor
                  </v-alert>
                </template>

                <template v-slot:[`item.Activo`]="{ item }">
                  <v-chip :color="getColor(item.Activo)" dark>
                    {{ retiros_automaticos(item.Activo) }}
                  </v-chip>
                </template>

                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar cliente  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar usuario</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editnombre"
                                    :counter="30"
                                    :rules="namenombre"
                                    label="Nombre"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <v-col cols="12">
                                  <v-select
                                    v-model="clientesIDedit"
                                    :items="clientesID"
                                    item-text="cliente"
                                    :item-value="'id'"
                                    :rules="[
                                      (v) => !!v || 'Cliente es requerido',
                                    ]"
                                    label="Cliente"
                                    required
                                    @change="cliente_cuenta(clientesIDedit)"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12">
                                  <!-- CUENTA EDIT-->
                                  <v-select
                                    v-model="cuentaNueva"
                                    :items="cuentas"
                                    item-text="cuenta"
                                    item-value="id"
                                    label="Cuentas"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item
                                        ripple
                                        @mousedown.prevent
                                        @click="toggle2"
                                      >
                                        <v-list-item-action>
                                          <v-icon
                                            :color="
                                              likesAllCuentasID
                                                ? 'indigo darken-4'
                                                : ''
                                            "
                                          >
                                            {{ icon2 }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            >TODAS LAS
                                            CUENTAS</v-list-item-title
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                  </v-select>
                                </v-col>
                                <!-- FIN SELECT CUENTA -->

                                <v-col cols="12">
                                  <!-- SELECT POS -->
                                  <v-select
                                    v-show="cajas.length > 0"
                                    v-model="cajaSelected"
                                    :items="cajas"
                                    item-text="descripcion"
                                    item-value="Id"
                                    label="Caja"
                                    :disable="cuenta.length == 0"
                                    no-data-text="No hay datos disponibles"
                                  ></v-select>
                                  <!-- FIN SELECT POS -->
                                </v-col>

                                <v-col cols="12" sm="12" md="12">
                                  <v-checkbox
                                    v-model="activo_user"
                                    class="ckeck"
                                    label="Activo/Inactivo"
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar_users"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip top color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        color="green darken-2"
                        @click="cargar_roles_users(item)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="!item.UserSistema"
                      >
                        assignment_ind
                      </v-icon>
                    </template>
                    <span>Roles {{ item.User }}</span>
                  </v-tooltip>
                  <v-icon color="orange" @click="editItem(item)">
                    create
                  </v-icon>
                </template>
                <!--END  BOTONES DE LA TABLA -->
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Users } from "../../services/users.js";
import { Clientes } from "../../services/clientes.js";
import { Cuentas } from "../../services/cuentas.js";
import { mapState } from "vuex";
export default {
  data: () => ({
    name: "Users",
    show: false, // para  mostrar el ojo en password
    show1: false, // para  mostrar el ojo en confirmar password
    desserts: [],
    // efecto cargando en tabla
    cargando: false,
    dialog: false,
    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos dee envio
    procensando_datos: false,
    // Filter models.
    searchUser: "",
    headers: [
      { text: "Usuario", value: "User" },
      { text: "Nombre", value: "Nombre" },
      { text: "Estado", value: "Activo" },
      { text: "Cliente del Usuario", value: "cliente.RazonSocial" },
      // { text: 'Users sistema', value: 'UserSistema[0].ID' },
      { text: "Acciones", value: "actions", sortable: false },
    ],

    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    //  numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    // nueva users POST
    createUsers: false,
    email: "",
    nombre: "",
    passwords: "",
    confirmar_password: "",
    clienteID: null,
    cajaSelected: "",
    cajas: [],
    clientesID: [],
    cuenta: [],
    cuentas: [],

    // campos a actualizar users PUT
    editIDusers: "",
    editnombre: "",
    // para actualizar se necesita enviar el id el cliente actual y el modificado
    clienteIdanterior: "",
    clientesIDedit: [],
    activo_user: false,
    cuentaNueva: [],
    clienteNuevo: null,
    // validaciones users
    valid: false,
    nameuser: [
      (v) => !!v || "Correo es requerido",
      (v) =>
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "El Email ingresado no es válido",
    ],
    namenombre: [
      (v) => !!v || "Nombre es requerido",
      (v) =>
        (v && v.length <= 50) ||
        "El campo Nombre no debe superar los 50 caracteres",
    ],
    namepassword: [
      (v) => !!v || "Contraseña es requerido",
      (v) =>
        (v && v.length <= 15) ||
        "El campo Contraseña no debe superar los 15 caracteres",
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },

    searchUser() {
      this.getdatos();
    },
  },
  //Cuanto se monta el componente se obtienen los datos de los usuarios y de los clientes

  mounted() {
    this.getdatos();
    this.getclientes();
  },
  //consultar todos los usuarios
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllCuentas) {
          this.cuenta = [];
        } else {
          if (this.cuenta.length === 0) {
            this.cuenta = this.cuentas.map((cuenta) => cuenta.id);
          }
        }
      });
    },

    toggle2() {
      this.$nextTick(() => {
        if (this.likesAllCuentasID) {
          this.cuentaNueva = [];
        } else {
          this.cuentaNueva = this.cuentas.map((cuenta) => cuenta.id);
        }
      });
    },

    getdatos: function() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        SistemaId: this.SISTEMA_ID,
        // ClienteId: this.cliente_ID,
        CargarUserSistema: true,
      };

      if (this.searchUser) {
        params.BusquedaNombreCorreo = this.searchUser;
      }
      this.cargando = true;
      Users.getUsers(params)
        .then((response) => {
          this.desserts = response.data.data;
          this.pagination.page = response.data.meta.page.currentPage;
          this.pagination.total = response.data.meta.page.lastPage;
          // if(response.data.data == null){
          //   this.desserts = [];
          // }else{
          //   this.desserts = response.data.data;

          // }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    // paginar
    onPageChange() {
      this.getdatos();
    },

    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },

    // cargar todos los clientes y cuentas
    getclientes: function() {
      const params = {
        CargarCuentas: true,
        Number: 1,
        Size: 100,
      };
      this.cargando = true;
      Clientes.getClientes(params)
        .then((response) => {
          if (response.data.data == null) {
            this.clientesID = [];
            this.cuentas = [];
          } else {
            this.clientesID = response.data.data;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },

    cliente_cuenta(clienteID) {
      this.cuenta = "";
      this.cuentaNueva = [];
      this.clienteNuevo = clienteID;

      const cliente = this.clientesID.find((c) => c.id === clienteID);

      if (cliente && Array.isArray(cliente.cuenta)) {
        this.cuentas = cliente.cuenta.map((cuenta) => ({
          ...cuenta,
          cuenta: `${cuenta.cuenta}`,
        }));
      } else if (cliente && cliente.cuenta) {
        this.cuentas = [
          {
            ...cliente.cuenta,
            cuenta: `${cliente.cuenta.cuenta}`,
          },
        ];
      }
      this.getPos(this.cuentas);
    },

    abrir_create_users() {
      this.createUsers = true;
      this.limpiar_campos();
    },

    cerrar_create() {
      this.cajas = [];
      this.createUsers = false;
      this.$refs.form_create_user.reset();
      this.limpiar_campos();
    },

    // guardar nuevo cliente
    async guardar_nuevo_user() {
      if (this.cuenta.length === this.cuentas.length) {
        this.cuenta = "";
      } else {
        this.cuenta = this.cuenta.join(",");
      }
      var payload = {
        User: this.email,
        Nombre: this.nombre,
        Password: this.passwords,
        SistemaId: this.SISTEMA_ID, // el sistema es siempre Pasarela
        ClienteId: this.clienteID, // Un usuario pertenece a un Cliente
        CuentasId: this.cuenta,
      };
      if (this.cajaSelected) {
        payload.CajaId = this.cajaSelected;
      }

      this.validate();
      this.checkpassword();
      if (this.res === true) {
        if (this.checkpass == false) {
          this.$toastr.e("Contraseña y confirmar contraseña deben ser iguales");
          e.preventDefault();
        }
        {
          this.error = false;
          this.procensando_datos = true;
          await Users.nuevo_user(payload)
            .then((response) => {
              this.getdatos();
              this.$toastr.s("La solicitud fue procesada con éxito");
              this.$refs.form_create_user.reset();
              this.createUsers = false;
            })
            .catch((error) => {
              this.errored = true;
              var data = error.response.data;
              this.error = true;
              this.loading = false;
              (this.checkpass = false), this.$toastr.e(data.message);
              this.dialog = false;
            })
            .finally(() => {
              this.procensando_datos = false;
              this.cuenta = [];
            });
        }
      } else {
        this.$toastr.e("Verificar datos de envio");
      }
    },

    // personalizar datos de la tabla
    getColor(value) {
      if (value == true) return "#43A047";
      else return "#EF5350";
    },

    retiros_automaticos(value) {
      if (value === true) {
        return "Activo";
      } else {
        return "Inactivo";
      }
    },

    //consultar los datos de la cuenta a modificar
    editItem(item) {
      this.consultar_users(item);
      this.getPos(this.cuentas);
      this.dialog = true;
    },

    async actualizar_users() {
      if (typeof this.clientesIDedit === "object") {
        var ClienteIdNuevo = this.clientesIDedit.id;
      } else {
        var ClienteIdNuevo = this.clientesIDedit;
      }

      // Manejo de cantidad de CuentaNueva para select
      if (this.cuentaNueva.length === this.cuentas.length) {
        //si la cantidad de cuentas seleccionadas tiene que mandar "" ya que todas es igual a ""
        this.cuentaNueva = "";
      } else {
        //si no son todas o al menos una
        this.cuentaNueva = this.cuentaNueva.join(",");
      }

      // Lo que manda al endpoint
      var payload = {
        Id: this.editIDusers,
        Nombre: this.editnombre,
        Activo: this.activo_user,
        ClienteIdAnterior: this.clienteIdanterior,
        CuentasId: this.cuentaNueva
          .split(",")
          .filter((value) => value !== "0")
          .join(","),
        SistemaId: this.SISTEMA_ID,
      };
      if (this.cajaSelected) {
        payload.CajaId = this.cajaSelected;
      }

      if (this.clienteNuevo != this.clienteIdanterior) {
        payload.ClienteIdNuevo = this.clienteNuevo;
      }

      this.validateupdate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await Users.actualizar_user(payload)
          .then((response) => {
            this.dialog = false;
            this.$toastr.s("El Usuario se actualizo correctamente");
            this.getdatos();
            this.close();
          })
          .catch((error) => {
            // this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //consultar users por id al editar
    consultar_users: function(item) {
      const params = {
        Id: item.Id,
        ClienteId: item.cliente.Id,
        SistemaId: this.SISTEMA_ID,
      };
      this.cliente_cuenta(item.cliente.Id);
      this.procensando_datos = true;
      Users.getUserID(params)
        .then((response) => {
          this.editIDusers = response.data.Id;
          this.editnombre = response.data.Nombre;
          this.activo_user = response.data.Activo; //recibir en el endpoint la cuenta que tiene el usuario
          if (response.data.caja) {
            this.cajaSelected = response.data.caja.Id;
          }

          this.clienteIdanterior = response.data.cliente.Id;
          this.clientesIDedit = {
            id: response.data.cliente.Id,
            cliente: response.data.cliente.RazonSocial,
          };
          if (response.data.CuentasId != "") {
            this.cuentaNueva = response.data.CuentasId.split(",").map(
              (item) => +item
            );
          } else {
            this.cuentaNueva = this.cuentas.map((cuenta) => ({
              ...cuenta,
              cuenta: `${cuenta.cuenta}`,
            }));
          }
        })
        .catch((error) => {
          var data = error.response.data;
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        })
        .finally(() => {
          this.procensando_datos = false;
        });
    },
    getPos: function(cuentas) {
      this.procensando_datos = true;
      let params = {
        CuentasId: cuentas.reduce((acc, cuenta, index) => {
          return acc + (index === 0 ? "" : ",") + cuenta.id;
        }, ""),
      };
      Cuentas.getDatosCaja(params)
        .then((response) => {
          if (response.data.data.CajasCobro) {
            this.cajas = response.data.data.CajasCobro;
          } else {
            this.cajas = [];
            this.cajaSelected = "";
          }
        })
        .catch((error) => {
          var data = error.response.data;
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        })
        .finally(() => {
          this.procensando_datos = false;
        });
    },

    getCajasCliente() {},
    close() {
      this.cliente = [];
      this.cajaSelected = "";
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validate() {
      this.$refs.form_create_user.validate();
      this.res = this.$refs.form_create_user.validate();
    },

    validateupdate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    checkpassword() {
      if (this.passwords !== this.confirmar_password) {
        this.checkpass = false;
      } else {
        this.checkpass = true;
      }
    },

    limpiar_campos() {
      this.email = "";
      this.nombre = "";
      this.passwords = "";
      this.confirmar_password = "";
      this.clienteID = "";
      this.cuenta = [];
      this.cuentas = [];
      this.clientesIDedit = "";
    },
    // ir a permisos de un rol
    cargar_roles_users(item) {
      // Un usuario puede tener varios sistemas_id. Se busca el que corresponde al sistema pasarela
      var usid_filtrado = item.UserSistema.filter(
        (usist) => usist.SistemasID == this.SISTEMA_ID
      );
      this.$router.push({
        name: "UserRol",
        // datos del UserSistema de un usuario especifico
        params: { user_id: item.Id, user_sistema_id: usid_filtrado[0].ID },
      });
    },
  },
  computed: {
    ...mapState(["SISTEMA_ID", "cliente_ID"]),
    likesAllCuentas() {
      if (this.cuenta.length === 0) {
        this.cuenta = this.cuentas.map((cuenta) => cuenta.id);
      }
      return this.cuenta.length === this.cuentas.length;
    },
    likesSomeCuentas() {
      return this.cuenta.length > 0 && !this.likesAllCuentas;
    },
    icon() {
      if (this.likesAllCuentas) return "mdi-close-box";
      if (this.likesSomeCuentas) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    // Para Edit
    likesAllCuentasID() {
      if (this.cuentaNueva.length === 0) {
        this.cuentaNueva = this.cuentas.map((cuenta) => cuenta.id);
      }
      return this.cuentaNueva.length === this.cuentas.length;
    },

    likesSomeCuentasID() {
      return this.cuentaNueva.length > 0 && !this.likesAllCuentasID;
    },
    //Para edit
    icon2() {
      if (this.likesAllCuentasID) return "mdi-close-box";
      if (this.likesSomeCuentasID) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
};
</script>
<style scoped>
/* .trow {
  transition: transform 0.3s;
}

.trow:hover {
  cursor: pointer;
  transform: scale(1.03);
  background: rgb(228, 228, 228);
  color: rgb(19, 18, 18);
} */

a[data-v-1b84c964] {
  color: #3aad1e;
}

.theme--light.v-btn {
  color: rgb(93 175 118 / 87%);
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
